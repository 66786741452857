import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {safePrefix} from '../utils';
import Header from './Header';
import Footer from './Footer';

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charset="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="google" content="notranslate" />

                    <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i" rel="stylesheet"/>
                    <link
                        rel="shortcut icon"
                        href={safePrefix("images/favicon.ico")}
                        type="image/x-icon"
                    />
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')} />

            <title>Idea to Prototype Service | Protoeasy</title>

        <meta name="description" content="Go from Idea to Prototypes in 2 Days Flat. Prototying Service starts at only $799. Save time, money and effort. Hire the domain experts at roadmapping."/>

        <link rel="canonical" href="https://www.protoeasy.com"/>

        <meta property="og:title" content="Idea to Prototype Service | Protoeasy"/>
        <meta property="og:description" content="Go from Idea to Prototypes in 2 Days Flat. Prototying Service starts at only $799. Save time, money and effort. Hire the domain experts at roadmapping."/>
        <meta property="og:image" content="https://images.ctfassets.net/6pbglmfv29x2/11JYcKK8AQyE3Sr8ANTe2a/e7b3d9a4728b7c83786b4c24e0f3c4a9/undraw_professor_8lrt.svg"/>
        <meta property="og:image:width" content="878"/>
        <meta property="og:image:height" content="365"/>
        <meta property="og:url" content="https://www.protoeasy.com"/>
        <meta property="og:site_name" content="Protoeasy Landing"/>
        <meta property="og:type" content="website"/>



                </Helmet>
                <div id="page" class={'site palette-' + _.get(this.props, 'pageContext.site.siteMetadata.palette')}>
                  <Header {...this.props} />
                  <main id="content" class="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                </div>
            </React.Fragment>
        );
    }
}
